.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    max-width: 80%;
    margin: 5% 0% 0% 10%;
    overflow: hidden;
}

.modal-overlay-calendar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    max-width: 80%;
    height: 100%;
    margin: 5% 0% 0% 10%;
    overflow: hidden;
}

.modal-content {
    background-color: rgb(250, 250, 250);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    /* Increase the max-width */
    width: 90%;
    /* Use a percentage width for responsiveness */
    position: relative;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
    max-height: 80vh;
    /* Ensure modal does not exceed the viewport height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.modal-content-calendar {
    background-color: rgb(250, 250, 250);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    /* Increase the max-width */
    width: 90%;
    /* Use a percentage width for responsiveness */
    position: relative;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
    max-height: 2000vh;
    /* Ensure modal does not exceed the viewport height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.modal-content.left {
    background-color: rgb(250, 250, 250);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 600px;
    /* Increase the max-width */
    width: 90%;
    /* Use a percentage width for responsiveness */
    position: relative;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
}

body.dark-mode .modal-content {
    background-color: #333;
    color: #fff;
    border: 1px solid #444;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
}

body.dark-mode .reveal-modal-content {
    background-color: #333;
    color: #fff;
    border: 1px solid #444;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
}

@media (max-width: 600px) {
    .modal-content {
        max-width: 90%;
        /* Ensure the modal is wide on smaller screens */
        padding: 15px;
    }
}

.reveal-modal-overlay {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    max-width: 80%;
    margin: 5% 0% 0% 10%;
    overflow: hidden;
}

.reveal-modal-content {
    background-color: rgb(250, 250, 250);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    /* Increase the max-width */
    width: 90%;
    /* Use a percentage width for responsiveness */
    position: relative;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
    max-height: 80vh;
    /* Ensure modal does not exceed the viewport height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
    /* Use the same color as the text */
}

.modal-content h1 {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
}

.modal-content p {
    font-size: 1em;
    margin: 10px 0;
}

.results-emoji {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    overflow-x: auto;
    /* Allow horizontal scrolling if content overflows */
    white-space: pre-line;
    /* This will handle the newlines */
}

.results-emoji span {
    font-size: 2em;
    margin: 0 5px;
    white-space: nowrap;
}

.result-message {
    font-size: 16px;
    margin: 20px 0;
}

.share-results-button {
    border: 2px solid #007bff;
    /* Blue border */
    border-radius: 25px;
    /* Rounded corners */
    padding: 10px 20px;
    /* margin: 0px 0px 60px 0px; */
    /* Padding */
    font-size: 16px;
    /* Font size */
    color: #000;
    /* Text color */
    background-color: #fff;
    /* Background color */
    cursor: pointer;
    /* Pointer cursor on hover */
    outline: none;
    /* Remove default outline */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
    font-family: 'NYTFranklin', Arial, sans-serif;
}

.share-results-button:hover:enabled {
    background-color: #007bff;
    color: #fff
        /* Light gray background on hover */
}

.view-results-button {
    border: 2px solid #007bff;
    /* Blue border */
    border-radius: 25px;
    /* Rounded corners */
    padding: 10px 20px;
    margin: 0px 0px 60px 0px;
    /* Padding */
    font-size: 16px;
    /* Font size */
    color: #000;
    /* Text color */
    background-color: #fff;
    /* Background color */
    cursor: pointer;
    /* Pointer cursor on hover */
    outline: none;
    /* Remove default outline */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
    font-family: 'NYTFranklin', Arial, sans-serif;
}

.view-results-button:hover:enabled {
    background-color: #007bff;
    color: #fff
        /* Light gray background on hover */
}

body.dark-mode .share-results-button {
    background-color: #444;
    border: 2px solid #666;
    color: #fff;
}

body.dark-mode .share-results-button:hover {
    background-color: #555;
}

body.dark-mode .view-results-button {
    background-color: #444;
    border: 2px solid #666;
    color: #fff;
}

body.dark-mode .view-results-button:hover {
    background-color: #555;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

/* Specific styling for the stats modal */
.stats-modal {
    width: 80%;
    max-width: 400px;
    text-align: center;
}

.stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stats-header {
    margin-bottom: 20px;
}

.stats-content {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.stat {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat-value {
    font-size: 2rem;
    font-weight: bold;
}

.stat-label {
    font-size: 1rem;
    color: gray;
}

/* Dark mode styling */
.dark-mode .modal {
    background: #333;
    color: white;
}

.dark-mode .stat-label {
    color: lightgray;
}

.dark-mode .close-button {
    background-color: #555;
}

.dark-mode .close-button:hover {
    background-color: #777;
}

.countdown {
    padding-top: 10px;
    padding-bottom: 20px;
}

.countdown-header {
    margin-top: 20px;
    text-align: center;
    font-size: 1em;
    color: #333;
}

.dark-mode .countdown-timer {
    color: #fff;
}

.feedback {
    text-align: center;
    margin: 20px;
}

.feedback-title {
    display: inline-block;
    margin-right: 10px;
}

.feedback-buttons {
    display: inline-block;
}

.feedback-button {
    display: inline-block;
    background: none;
    border: none;
    font-size: 24px;
    margin: 0 5px;
    cursor: pointer;
}

.game-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns */
    gap: 15px;
    /* Space between items */
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.game-card {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.2s ease-in-out;
}

.game-card:hover {
    transform: scale(1.05);
}

.game-logo {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.game-title {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.dark.game-title {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.modal-content.left .more-games-title {
    text-align: center;
    ;
}