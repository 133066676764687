.card-header {
    margin-bottom: 50px;
}

.movie-levels {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap to the next row */
    justify-content: space-between;
    /* Adjust spacing between items */
    align-items: flex-start;
    /* Align items at the top */
    margin: 20px 0;
}

.movie-quiz {
    position: relative;
    padding: 1em;
    margin: 1em;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
    width: 30%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1 1 20%;
}

.movie-quiz.dark-mode {
    position: relative;
    padding: 1em;
    margin: 1em;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
    width: 30%;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
    flex: 1 1 20%;
}

/* Background colors for each level */
.movie-quiz.easy {
    background-color: #a0c35a;
}

.movie-quiz.easy.dark-mode {
    background-color: #4c6e07;
}

.movie-quiz.medium {
    background-color: #b0c4ef;
}

.movie-quiz.medium.dark-mode {
    background-color: #0d3488;
}

.movie-quiz.hard {
    background-color: #ba81c5;
}

.movie-quiz.hard.dark-mode {
    background-color: #542b5c;
}

/* Correct answer border */
.movie-quiz.easy.done {
    border: 4px solid #4caf50;
}

.movie-quiz.medium.done {
    border: 4px solid blue;
}

.movie-quiz.hard.done {
    border: 4px solid purple;
}

.arrow-button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-button svg {
    width: 40px;
    height: 40px;
}

.arrow-button.dark svg path {
    fill: white;
}

.arrow-button.light svg path {
    fill: black;
}


.mistakes {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    z-index: 1;
    font-weight: normal;
}

.reveal {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: left;
    z-index: 1;
}

.revealButton {
    font-size: 1.5em;
    z-index: 1;
    font-weight: normal;
    font-family: 'NYTFranklin', Arial, sans-serif;
    background: transparent;
    border: none;
    color: black;
}

.revealButton.dark-mode {
    font-size: 1.5em;
    z-index: 1;
    font-weight: normal;
    font-family: 'NYTFranklin', Arial, sans-serif;
    background: transparent;
    border: none;
    color: white;
}

.dot {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0 2px;
    background-color: lightgray;
    border-radius: 50%;
}

.dot.used {
    background-color: white;
}

.movie-content {
    text-align: center;
    margin-top: 2em;
    /* Adjust this margin to create space for the mistakes div */
}

/* Loading spinner */
.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 10px auto;
}

.input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    max-width: 600px;
    /* Optional: Limit maximum width */
    justify-content: center;
    /* Center align the content */

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Media query for smaller screens */
@media (max-width: 800px) {
    .movie-quiz {
        flex: 1 1 45%;
        /* Allow more space for smaller screens */
    }

    .movie-quiz.dark-mode {
        flex: 1 1 45%;
        /* Allow more space for smaller screens */
    }
}

/* Media query for very small screens */
@media (max-width: 500px) {
    .movie-quiz {
        flex: 1 1 100%;
        /* Full width for very small screens */
    }

    .movie-quiz.dark-mode {
        flex: 1 1 100%;
        /* Full width for very small screens */
    }
}