.styled-input {
    border: none;
    border-bottom: 2px solid black;
    background-color: transparent;
    font-family: 'NYTFranklin', Arial, sans-serif;
    font-size: 1em;
    padding: 20px;
    outline: none;
    text-align: center;
    width: auto;
    /* Allow input to take up necessary space */
    flex: 1;
    /* Allow the input to take up remaining space */
    margin-right: 5%;
    margin-left: 20%;
    /* Space between input and button */
}

.styled-input.dark-mode {
    border: none;
    border-bottom: 2px solid #ffffff;
    background-color: transparent;
    font-family: 'NYTFranklin', Arial, sans-serif;
    font-size: 1em;
    padding: 20px;
    outline: none;
    text-align: center;
    width: auto;
    /* Allow input to take up necessary space */
    flex: 1;
    /* Allow the input to take up remaining space */
    margin-right: 5%;
    margin-left: 20%;
    /* Space between input and button */
}

.styled-input::placeholder {
    color: black;
    /* Replace with the desired color */
}

.styled-input.dark-mode::placeholder {
    color: #fff;
    /* Replace with the desired color for dark mode */
}


.subtitle {
    margin-top: 10px;
    font-size: 1em;
    color: #000;
}