@font-face {
    font-family: 'NYTFranklin';
    src: url('/fonts/NYTFranklin.woff2') format('woff2'),
        url('/fonts/NYTFranklin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.theme-container {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: 'NYTFranklin', Arial, sans-serif;
}

.theme-container.dark-mode {
    background-color: #333;
    color: #fff;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: 'NYTFranklin', Arial, sans-serif;
}

.theme-header {
    background-color: #aedfee;
    color: #000;
    text-align: center;
    padding: 10px;
    font-weight: bold;
}

.theme-header.dark-mode {
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-weight: bold;
}

.theme-content {
    background-color: #fff;
    color: #000;
    text-align: center;
    padding: 20px;
    font-size: 1.5em;
    font-weight: bolder;
}

.theme-content.dark-mode {
    background-color: #222;
    color: #fff;
    /* text-align: center;
    padding: 20px;
    font-size: 1.5em;
    font-weight: bolder; */
}