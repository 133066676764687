.update-banner {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: white;
    padding: 8px;
    text-align: left;
    z-index: 1000;
    font-size: 1rem;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}

.update-banner-content {
    display: flex;
    justify-content: center;
    /* Center the text inside the banner */
    align-items: center;
    width: 100%;
    /* Ensure the content spans the full width */
}

.update-banner p {
    /* Remove default margin */
    text-align: center;
    margin-right: 5%;
    /* Center the text */
}

.dismiss-btn {
    background: none;
    border: 1px solid white;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.dismiss-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

p {
    align-items: center;
}