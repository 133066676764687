@font-face {
  font-family: 'NYTFranklin';
  src: url('/fonts/NYTFranklin.woff2') format('woff2'),
    url('/fonts/NYTFranklin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  font-family: 'NYTFranklin', Arial, sans-serif;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center elements horizontally */
  padding: 10px;
  margin: 10px;
}

.light-mode {
  background-color: white;
  color: black;
  /* other light mode styles */
}

.dark-mode {
  color: white;
  /* other dark mode styles */
}

body.light-mode {
  background-color: white;
  color: black;
  /* other light mode styles */
}

body.dark-mode {
  background-color: #121212;
  color: white;
  /* other dark mode styles */
}

.emoji {
  font-size: 40px;
  margin: 20px;
}

.movie-title {
  font-size: 20px;
  margin: 10px 0;
  /* any other styling you want */
}

.movie-levels {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next row */
  justify-content: space-between;
  /* Adjust spacing between items */
  align-items: flex-start;
  /* Align items at the top */
  margin: 20px 0;
}