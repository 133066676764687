.hint-button {
    font-size: 1.2em;
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    font-family: 'NYTFranklin', Arial, sans-serif;
}

.hint-button.dark-mode {
    color: white;
    background: none;
}

.dotted-line {
    margin: 0 5px;
    color: #000000;
}