.ranking-system {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.rank {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    margin: 5px 0;
}

.rank.current-rank {
    background-color: #aedfee;
    font-weight: bold;
}

.rank.current-rank.dark-mode {
    background-color: #1e4551;
    font-weight: bold;
}

.rank-level {
    font-size: 1rem;
}

.rank-score {
    font-size: 1rem;
    color: gray;
}

.current-rank-details {
    margin-top: 20px;
}

.current-rank-level {
    font-size: 1.5rem;
    font-weight: bold;
}

.points-to-next-rank {
    font-size: .75rem;
    color: gray;
    padding: 5px;
}