.mode.dark {
    border: 2px solid #ffffff;
    /* Blue border */
    border-radius: 25px;
    /* Rounded corners */
    padding: 10px 20px;
    /* Padding */
    font-size: 16px;
    /* Font size */
    color: #fff;
    /* Text color */
    background-color: black;
    /* Background color */
    cursor: pointer;
    /* Pointer cursor on hover */
    outline: none;
    /* Remove default outline */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
    font-family: 'NYTFranklin', Arial, sans-serif;
}

.mode.light {
    /* border: 2px solid #aedfee; */
    /* Blue border */
    border-radius: 25px;
    /* Rounded corners */
    padding: 10px 20px;
    /* Padding */
    font-size: 16px;
    /* Font size */
    color: #000;
    /* Text color */
    background-color: #fff;
    /* Background color */
    cursor: pointer;
    /* Pointer cursor on hover */
    outline: none;
    /* Remove default outline */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
    font-family: 'NYTFranklin', Arial, sans-serif;
}

/* Media query for smaller screens */
@media (max-width: 500px) {

    /* Hide text */
    .toolbar-text {
        display: none;
    }

    /* Adjust emoji size */
    .toolbar-emoji {
        font-size: 2rem;
    }

    .mode.dark {
        border: none;
    }

    .mode.light {
        border: none;
    }
}