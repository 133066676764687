/* Light Mode (Default) */
.date-picker-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 320px;
    z-index: 9999;
    /* Make sure it's above other content */
}

/* Dark Mode */
.dark-mode .date-picker-modal {
    background-color: #333;
    color: white;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

/* Background overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.react-datepicker {
    border: none;
    width: 100%;
    background: none;
}

.react-datepicker__header {
    background-color: transparent;
    padding: 10px;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__month {
    display: flex;
    flex-direction: column;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: space-around;
}

.dark-mode .react-datepicker__day-names,
.dark-mode .react-datepicker__week {
    color: white;
    display: flex;
    justify-content: space-around;
}

.react-datepicker__day-name,
.react-datepicker__day {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
}

.dark-mode .react-datepicker__day-name,
.dark-mode .react-datepicker__day {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
    color: white;
}

.react-datepicker__day:hover {
    background-color: #90caf9 !important;
}

.dark-mode .react-datepicker__day:hover {
    background-color: #2196F3;
}

.react-datepicker__day--today-utc {
    border: 3px solid black;
    border-radius: 5px;
    background-color: #FFC107;
}

.dark-mode .react-datepicker__day--today-utc {
    border: 3px solid black;
    border-radius: 5px;
    color: black;
}

.react-datepicker__day--selected {
    background-color: #90caf9;
    border-radius: 5px !important;
    /* border: 2px solid #90caf9 !important; */
    color: black;
}

.dark-mode .react-datepicker__day--selected {
    background-color: #90caf9;
    border-radius: 5px !important;
    /* border: 2px solid #2196F3 !important; */
    color: black;
}

.react-datepicker__day--disabled {
    color: #ccc;
}

.dark-mode .react-datepicker__day--disabled {
    color: #777;
}

.react-datepicker__navigation-icon::before {
    border-color: #2196F3;
}

.dark-mode .react-datepicker__navigation-icon::before {
    border-color: #90caf9;
}

/* Month and year dropdown styling */
.react-datepicker__month-dropdown-container select,
.react-datepicker__year-dropdown-container select {
    appearance: none;
    font-size: 1rem;
    font-weight: bold;
    background-color: white;
    color: #2196F3;
    border: none;
    cursor: pointer;
}

.dark-mode .react-datepicker__month-dropdown-container select,
.dark-mode .react-datepicker__year-dropdown-container select {
    background-color: #333;
    color: #90caf9;
}

.react-datepicker__month-dropdown-container select:hover,
.react-datepicker__year-dropdown-container select:hover {
    background-color: #1e88e5;
    color: white;
}

.dark-mode .react-datepicker__month-dropdown-container select:hover,
.dark-mode .react-datepicker__year-dropdown-container select:hover {
    background-color: #90caf9;
    color: black;
}

.react-datepicker__month-dropdown-container::after,
.react-datepicker__year-dropdown-container::after {
    content: "▾";
    font-size: 14px;
    margin-left: 5px;
    color: #007bff;
}

.dark-mode .react-datepicker__month-dropdown-container::after,
.dark-mode .react-datepicker__year-dropdown-container::after {
    color: #90caf9;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
    display: inline-block;
    position: relative;
    padding-right: 15px;
    border: 1px solid#2196F3;
    border-radius: 100px;
    color: #007bff;
    font-size: 16px;
    padding: 5px 10px;
}

.dark-mode .react-datepicker__month-dropdown-container,
.dark-mode .react-datepicker__year-dropdown-container {
    border: 1px solid #90caf9;
    color: #90caf9;
}

.react-datepicker__navigation-icon::before {
    border-color: #2196F3;
}

.dark-mode .react-datepicker__navigation-icon::before {
    border-color: #90caf9;
}

.react-datepicker__current-month {
    display: none;
}

.button-date-picker {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #2196F3;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
}

button:hover {
    color: white;
}

/* Dark Mode button */
.dark-mode button {
    color: white;
}

/* Close Button */
.modal-close-button {
    position: absolute;
    top: 1%;
    right: 1%;
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #2196F3;
    cursor: pointer;
    z-index: 1000;
    /* Ensure it's above other content */
}

.close-container {
    padding: 8px;
}

/* Styling for completed quiz dates */
.completed-quiz-date {
    background-color: #FFC107 !important;
    color: black !important;
    border-radius: 100%;
}

.completed-quiz-date:hover {
    color: black !important;
    border-radius: 100%;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    position: relative;
    margin-bottom: 5%;
}

.react-datepicker__navigation {
    top: 3%;
}