.toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.toolbar-button {
    margin-left: 10px;
}

.theme-toggle {
    margin-left: 10px;
}