/* Loading container styling */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #333;
    /* Adjust for dark mode if needed */
    color: #fff;
    /* Text color */
    opacity: 1;
    visibility: visible;
    z-index: 9999;
    /* Ensure it's on top of other elements */
    transition: opacity 3s ease, visibility 3s ease;
    /* Smooth transition for fade-out */
}

/* Loading spinner styling */
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 3s infinite linear;
    margin-bottom: 10px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* Fade-out animation when loading completes */
.loading-container.fade-out {
    opacity: 0;
    visibility: hidden;
}